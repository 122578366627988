.characterSelect {
  display: flex;
  justify-content: center;
  max-width: 100%;
  box-sizing: border-box;
  margin: 0 auto;
}

@media (max-width: 500px) {
  .characterSelect {
    max-width: 360px;
  }
  [data-role="avatar"] {
    width: 20%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
