$primary: #3b81a7;
$primary-hover: #4f97be;
$mute-bg: #e6e6e6;

$box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);

$screen-sm: 30em;
$screen-md: 50em;
$screen-lg: 60em;

@font-face {
  font-family: 'monorgomodularRegular';
  src: url("./fonts/monorgomodularRegular.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}

html {
  position: relative;
  height: 100%;
  width: 100vw;
  margin: 0 !important;
  padding: 0 !important;
  overscroll-behavior: none;
}

body {
  position: relative;
  height: 100%;
  width: 100%;
  overscroll-behavior: none;
  margin: 0 !important;
  padding: 0 !important;
	background-color: $mute-bg;
	font-family: 'monorgomodularRegular', sans-serif;
}

.touch .hide-touch {
  display: none !important;
}
.no-touch .hide-no-touch {
  display: none !important;
}

#game {
	position: relative;
	max-width: 600px;
	height: 90vh;
	margin: 0 auto;
	overflow: hidden;
  box-sizing: border-box;
}

#game canvas {
	position: absolute;
	display:block;
  margin: 0;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
	width: 100%;
	height: 100%;
  padding-top: 50px;
  padding-right: 90px;
  background-color: $mute-bg;
}





.flex-wrap {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.flex-start {
  display: flex;
  justify-content: flex-start;
}
.flex-start-v-center {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.flex-end {
  display: flex;
  justify-content: flex-end;
}
.flex-center {
  display: flex;
  justify-content: center;
}
.flex-center-v-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-between {
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
}
.flex-between-v-center {
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flex-around {
  display: flex;
  justify-content: space-around;
}
.align-center {
  display: flex;
  align-items: center;
}
.flex-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between; 
}
.flex-column-center {
  display: flex;
  flex-direction: column;
  justify-content: center; 
}
.flex-column-end {
  display: flex;
  flex-direction: column;
  justify-content: flex-end; 
}
.flex-column-between {
  display: flex;
  flex-direction: column;
  justify-content: space-between; 
}

.flex-perfect-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

// CONTROLS //
///MOBILE CONTROLS///
.mobile-control__wrapper {
  position: absolute;
  bottom: 80px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 135px;
  height: 135px;
  pointer-events: none;
  -webkit-touch-callout: none;
    -webkit-user-select: none;
     -khtml-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.mobile-control {
  position: absolute;
  width: 45px;
  height: 45px;
  color: black;
  text-align: center;
  font-size: 1.2em;
  box-sizing: border-box;
  -webkit-touch-callout: none;
    -webkit-user-select: none;
     -khtml-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}
.mobile-control__wheel {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 1px solid black;
  -webkit-touch-callout: none;
    -webkit-user-select: none;
     -khtml-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}
.mobile-control__wheel-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid black;
}


.mobile-control[data-direction='up'] {
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
}
.mobile-control[data-direction='up-left'] {
  top: 0;
  left: 0;
  border-radius: 100% 0% 0% 0%;
}
.mobile-control[data-direction='up-right'] {
  top: 0;
  right: 0;
  border-radius: 0% 100% 0% 0%;
}

.mobile-control[data-direction='left'] {
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  padding-left: 8px;
}
.mobile-control[data-direction='right'] {
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  text-align: right;
  padding-right: 8px;
}

.mobile-control[data-direction='down'] {
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
}
.mobile-control[data-direction='down-left'] {
  bottom: 0;
  left: 0;
  border-radius: 0% 0% 0% 100%;
}
.mobile-control[data-direction='down-right'] {
  bottom: 0;
  right: 0;
  border-radius: 0% 0% 100% 0%;
}

.no-touchevents {
  .mobile-control__wrapper {
    display: none;
  }
}

// SCREENS //
.start-title {
  position: relative;
  max-width: 450px;
  margin: 0 auto;
  z-index: -1;
  pointer-events: none;
}
@media (max-width: $screen-md) {
  .start-title {
    max-width: 100%;
  }
}

.start-band-members {
  position: relative;
  z-index: 1;
}

#password-screen {
  z-index: 999999;
}

@media screen and (orientation: portrait) {
  .touch {  
    #orientation-warning {
      visibility: hidden;
      opacity: 0;
      z-index: -99999;
    }
  }
}
@media screen and (orientation: landscape) {
  .touch {  
    #orientation-warning {
      visibility: visible;
      opacity: 1;
      z-index: 99999;
    }
  }
}

.screen {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: $mute-bg;
	visibility: hidden;
	opacity: 0;
	z-index: -1;
}
.screen[data-active="true"] {
	visibility: visible;
	opacity: 1;
	z-index: 1;
}

// CHARACTERS //
.avatar {
  width: 100px;
  cursor: pointer;

  img {
    pointer-events: none;
  }
}
.avatar.active,
.avatar:hover {
  box-shadow: $box-shadow;
}

// TYPOGRAPHY //
a, a:hover, a:link, a:visited, a:active {
  text-decoration: none;
  cursor: pointer;
}

.text-center {
  text-align: center;
}
.title {
  font-size: 3em;
}
.title-sm {
  font-size: 2.3em;
}

.table {
  border-collapse: collapse;
  width: 100%;
}
.table td{
  padding: 0px;
}
.table td:nth-child(1) { text-align: left;}
.table td:nth-child(2) { text-align: left;}
.table td:nth-child(3) { text-align: right;}

// BUTTONS //
.pointer {
  cursor: pointer !important;
}
.btn {
  cursor: pointer !important;
  text-transform: uppercase;
  border: none;
  vertical-align: middle;
  font-size: 1.2em;
  font-family: 'monorgomodularRegular', sans-serif;
}

.btn:focus {
  outline: none;
}

.btn-pill {
  padding: 4px 16px;
  //line-height: 12px;
  -webkit-border-radius: 18px;
  -moz-border-radius: 18px;
  border-radius: 50px;
  letter-spacing: 2px;
  margin: 15px 0px;
}
.btn-inline {
  display: inline-block;
}
.btn-pill-lg {
  font-size: 1.5em;
  padding: 12px 16px;
}
.btn-pill-xl {
  padding: 14px 24px;
}
@media (max-width: $screen-sm) {
  .btn-pill-xl {
    padding: 10px 18px;
  }
}
.btn-pill img{
  height: 20px;
  vertical-align: middle;
  margin-bottom: 4px;
}
.btn-clear {
  border: 1px solid white;
  color: white !important;
}

.btn-primary {
  background-color: $primary;
  color: white;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  transition: all 300ms ease;
}
.btn-primary:hover {
  background-color: $primary-hover;
  color: white;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  transition: all 300ms ease;
}

.btn-spotify, .btn-spotify:hover {
  background-color: #2AB858;
  color: white;
}
.spotify-not-supported {
  display: none;
  background-color: grey;
  color: white;
  cursor: not-allowed;
  font-size: 0.8em;
}
.btn-apple, .btn-apple:hover {
  background-color: black;
  color: white;
}

.btn-block {
  display: block;
  width: 100%;
  box-sizing: border-box;
}

.social-cta {
  margin-bottom: 30px;
}
.social-share {
  font-family: sans-serif;
  letter-spacing: 4px;
  font-size: 0.8em;
  margin: 0 5px;
}
.social-share a{
  margin-left: 15px;
}
.btn-twitter {
  color: white;
  background-color: #1DA1F2;
}
.btn-facebook {
  color: white;
  background-color: #4267B2;
}

#open-leaderboard {
  display: none;
}
// NAV //
.cta {
  position: absolute;
  top: 60px;
  left: 0;
  right: 0;
  width: 90%;
  margin: 0 auto;
  text-align: center;
}

.hide-touch.cta {
  top: 10px;
  left: auto;
  right: 20px;
  width: 200px;
}


.game-stats {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 50%;
  box-sizing: border-box;
}
.game-stats__health {
  width: 150px;
  box-sizing: border-box;
}
.health-icon {
  max-height: 40px;
}


// TOUCH DEVICES
.touchevents {
  .game-stats {
    position: relative;
    width: 98%;
    left: 0;
    display: flex;
    margin: 0 auto;
    top: -50px;
    box-sizing: border-box;
    justify-content: space-between;
  }
  #game {
    height: 78vh;
  }
  #game canvas{
    padding-top: 100px;
    padding-left: 10%;
    border: 2px solid green;
  }
  .health-icon {
    height: 35px;
  }
  .game-stats__health {
    width: 100px;
  }
}


// SMALL DESKTOP WINDOWS
@media (max-width: $screen-md) {
  .no-touch {
    #game canvas{
      padding-top: 100px;
    }
    #game {
      height: 85vh;
    }
    .game-stats {
      position: absolute;
      top: 5px;
    }
  }
}
.touch .cta {
  top: 45px;
}

@media (max-width: $screen-md) {
  
}

// IPAD PRO
@media (max-width: 1080px) and (max-height: 1650px) and (min-height: 1024px) {  
  
}

// IPAD
@media (max-width: 780px) and (max-height: 1050px) and (min-height: 850px) {  
  
}

// SMALL DESKTOP WINDOWS
@media (max-width: $screen-md) and (max-height: 740px) {
  
}

// SMALL PHONES
@media (max-width: $screen-md) and (max-height: 780px) {
  
}

// X SMALL PHONES
@media (max-width: $screen-md) and (max-height: 630px) {
  .mobile-control__wrapper {
    bottom: 2px;
    width: 105px;
    height: 105px;
  }
  .mobile-control {
    position: absolute;
    width: 35px;
    height: 35px;
  }
  .touch #game canvas {
    padding-top: 100px;
  }
}

